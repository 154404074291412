import { IntlShape } from "react-intl";

export const getWhatsappTemplate = (intl: IntlShape) => [
    {
        message: intl.formatMessage({ id: "WHATSAPP_CHAT_TEMPLATE_1" })
    },
    {
        message: intl.formatMessage({ id: "WHATSAPP_CHAT_TEMPLATE_2" })
    },
    {
        message: intl.formatMessage({ id: "WHATSAPP_CHAT_TEMPLATE_3" })
    }
]