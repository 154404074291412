import React, { useState } from 'react';
import './ContactUs.less';
import fbIcon from '../../assets/icons/icons8-facebook.svg'; 
import igIcon from '../../assets/icons/icons8-instagram.svg';
import xhsIcon from '../../assets/icons/xiaohongshu.svg';
import Header from '../../components/header/header-top/Header';
import HeaderCollapse from '../../components/header/header-collapse/HeaderCollapse';
import WhatsappButton from '../../components/whatsapp-button/WhatsappButton';
import Footer from '../../components/footer/Footer';
import appLogo from '../../assets/images/logo/logo-gold-with-word.svg';
import WhatsappLogo from '../../assets/icons/icons8-whatsapp.svg';
import { factoryAddress, officeAddress, contactNumber, salesContactLink, fbContactLink, igContactLink, xhsContactLink } from '../../content/CompanyContent';
import { useIntl } from 'react-intl';
import emailjs from 'emailjs-com';

const ContactUs = () => {
    const [isScrollButtonVisible, setIsScrollButtonVisible] = useState(false);
    const intl = useIntl();

    const [formData, setFormData] = useState({
        name: '',
        company_name: '',
        phone_number: '',
        email: '',
        message: '',
      });
    
      const [isSubmitting, setIsSubmitting] = useState(false);
      const [isSuccess, setIsSuccess] = useState(false);
      const [isError, setIsError] = useState(false);
    
      const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
      };
    
      const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        setIsSubmitting(true);
    
        // EmailJS configuration
        const serviceID = 'service_edyi7s8';
        const templateID = 'template_rujm9ki';
        const userID = '__Rb2fRgyJk-Ui-km'; //public key
    
        emailjs.send(serviceID, templateID, formData, userID)
        .then((response) => {
            console.log('SUCCESS!', response.status, response.text);
            setIsSuccess(true);
            setTimeout(() => {
                setIsSuccess(false);
            }, 5000);
            setIsSubmitting(false);
    
            // Reset form fields
            setFormData({
              name: '',
              company_name: '',
              phone_number: '',
              email: '',
              message: '',
            });
        })
        .catch((err) => {
            console.log('FAILED...', err);
            setIsError(true);
            setIsSubmitting(false);
            setTimeout(() => {
                setIsError(false);
            }, 5000);
        });
    };

    return (
        <div className="contact-us-container">
            <Header isSubPage={true} />
            <HeaderCollapse isSubPage={true} />

            <div className="contact-us-content">
                <div className="contact-info">
                    <div className="contact-us-header">
                        <img src={appLogo} alt="Forest Birdnest" className="logo-contact" />
                    </div>
                    <h2>{intl.formatMessage({ id: "OFFICE" })}</h2>
                    <p>                    
                        {officeAddress.split("\n").map((line: any) => (
                            <>
                                {line}
                                <br />
                            </>  
                        ))}
                    </p>
                    <h2>{intl.formatMessage({ id: "FACTORY" })}</h2>
                    <p>
                        {factoryAddress.split("\n").map((line: any) => (
                            <>
                                {line}
                                <br />
                            </>  
                        ))}
                    </p>
                    <h2>{intl.formatMessage({ id: "CONTACT_NO" })}</h2>
                    <p>{contactNumber}</p>
                    <div className="whatsapp-button-contact">
                        <a href={salesContactLink} target="_blank" rel="noopener noreferrer">
                            <button className="whatsapp-button-contact-text">
                                <img src={WhatsappLogo} alt="WhatsApp" className="whatsapp-logo" />
                                {intl.formatMessage({ id: "CONTACT_BTN_TXT" })}
                            </button>
                        </a>
                    </div>
                </div>
                <div className="contact-form">
                    <h2>{intl.formatMessage({ id: "TALK_TO_US" })}</h2>
                    <form onSubmit={handleSubmit}>
                        <input 
                            type="text" 
                            placeholder={intl.formatMessage({ id: "NAME_PLACEHOLDER" })} 
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required 
                        />
                        <input 
                            type="text" 
                            placeholder={intl.formatMessage({ id: "COMPANY_PLACEHOLDER" })} 
                            name="company_name"
                            value={formData.company_name}
                            onChange={handleChange}
                            required 
                        />
                        <input 
                            type="text" 
                            placeholder={intl.formatMessage({ id: "PHONE_PLACEHOLDER" })} 
                            name="phone_number"
                            value={formData.phone_number}
                            onChange={handleChange}
                            required 
                        />
                        <input 
                            type="email" 
                            placeholder={intl.formatMessage({ id: "EMAIL_PLACEHOLDER" })} 
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                        <textarea 
                            placeholder={intl.formatMessage({ id: "MESSAGE_PLACEHOLDER" })}
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                        />
                        <button type="submit" className="submit-button-contact" disabled={isSubmitting}>{intl.formatMessage({ id: "SUBMIT" })}</button>
                    </form>
                    {isSuccess && <p className="success-message">{intl.formatMessage({ id: "CONTACT_MESSAGE_SENT_SUCCESFULLY" })}</p>}
                    {isError && <p className="error-message">{intl.formatMessage({ id: "CONTACT_MESSAGE_SENT_FAILED" })}</p>}
                    <div className="social-media">
                        <div className="social-icon">
                            <a href={fbContactLink} target="_blank">
                                <img src={fbIcon} alt="Facebook" />
                            </a>
                            <p>森之燕燕窝</p>
                        </div>
                        <div className="social-icon">
                            <a href={igContactLink} target="_blank">
                                <img src={igIcon} alt="Instagram" />
                            </a>
                            <p>Forest Birdnest</p>
                        </div>
                        <div className="social-icon">
                            <a href={xhsContactLink} target="_blank">
                                <img src={xhsIcon} alt="XHS" />
                            </a>
                            <p>森之燕燕窝</p>
                        </div>
                    </div>
                </div>
            </div>
            <WhatsappButton isScrollButtonVisible={isScrollButtonVisible} />
            <Footer isSubPage={true} />
        </div>
    );
};

export default ContactUs;
