import React, { useState } from "react";
import { Collapse } from "antd";
import { useIntl } from "react-intl";
import { getFaqContent } from "../../content/FaqContent";
import Header from "../../components/header/header-top/Header";
import Footer from "../../components/footer/Footer";
import WhatsappButton from "../../components/whatsapp-button/WhatsappButton";
import appLogo from '../../assets/images/logo/logo-gold-with-word.svg';
import "./Faq.less";
import HeaderCollapse from "../../components/header/header-collapse/HeaderCollapse";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";

const Faq = () => {
    const [isScrollButtonVisible, setIsScrollButtonVisible] = useState(false);
    const intl = useIntl();
    const FaqContent = getFaqContent(intl);

    return (
        <div className="faq-container">
            <Header isSubPage={true} />
            <HeaderCollapse isSubPage={true} />
            <div className="company-logo">
                <img alt="company-logo-hero" className="company-logo-hero" src={appLogo} />
            </div>
            <div className="faq-content-container">
                <div className="faq-header">{intl.formatMessage({ id: "FAQ" })}</div>
                {intl.locale !== 'zh-CN' && (
                    <div className="faq-sub-header">{intl.formatMessage({ id: "FAQ_SUB_HEADER" })}</div>
                )}
                <div className="faq-main-header">{intl.formatMessage({ id: "FAQ_MAIN_HEADER" })}</div>
                <div className="faq-qna-container">
                    {FaqContent.map((faq, i) => (
                        <div className="faq-qna-item-container">
                            <Collapse
                                className="faq-qna-item-collapse"
                                bordered={false}
                                expandIconPosition="end"
                                collapsible="header"
                                size="large"
                                expandIcon={({ isActive }) => isActive ? <MinusOutlined /> : <PlusOutlined />}
                                items={[
                                    {
                                        forceRender: true,
                                        key: i,
                                        label: <><span className="faq-question-number">{i + 1}.</span> {faq.question}</>,
                                        children: <p>{faq.answer}</p>,
                                    },
                                ]}
                            />
                        </div>
                    ))}
                </div>
            </div>
            <WhatsappButton isScrollButtonVisible={isScrollButtonVisible} />
            {/* <ScrollToTopButton setIsScrollButtonVisible={setIsScrollButtonVisible} /> */}
            <Footer isSubPage={true} />
        </div>
    );
};

export default Faq;
