import React from "react";
import logoGold from '../../assets/images/logo/logo-gold.png';
import leaf from '../../assets/images/leaf-left.svg';

import "./SubHero.less";

const SubHero = () => {
    return (
        <div className="sub-hero-container">
            <div className="leaves-container">
                <div className="leaf-left-container">
                    <img src={leaf} alt="leaf-left" className="leaf-left" />
                </div>
                <div className="logo-middle-container">
                    <img src={logoGold} alt="logo" className="logo-middle" />
                </div>
                <div className="leaf-right-container">
                    <img src={leaf} alt="leaf-right" className="leaf-right" />
                </div>
            </div>
        </div>
    );
}

export default SubHero;
