export const companyName = "Forest Birdnest House Sdn Bhd";
export const companyRegistrationNumber = "201101027044 (955179-D)";
export const officeAddress = "2nd Floor, Lot TC-05, IOI Galleria, \nThe Cube, 2, Jln Puteri 7/13A, \nBandar Puteri Puchong, \n47100, Selangor, Malaysia";
export const factoryAddress = "No. 9, Jalan MJ 15, \nTaman Industri Meranti Jaya, \n47120 Puchong, Selangor";
export const contactNumber = "+6010-9669619";
export const enquiryEmail = "sales@forestbirdnest.com";
export const salesContactLink = "https://api.whatsapp.com/send?phone=60109669619";
export const currencyCode = "RM ";
export const fbContactLink = "https://www.facebook.com/senzhiyanbirdnest?mibextid=kFxxJD";
export const xhsContactLink = "https://www.xiaohongshu.com/user/profile/6522566200000000240149fe?xhsshare=CopyLink&appuid=5f8ff875000000000101e1f5&apptime=1725447300&share_id=ee17d9d2a3b94c65a3decd88ae41d10f";
export const igContactLink = "https://www.instagram.com/forestbirdnest?igsh=cmxtcDg4dHNyZHhh";