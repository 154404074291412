import React, { useState } from "react";
import './CardComponent.less';

interface CardComponentProps {
    card: {
        imageSrc: string;
        title: string;
        description: string;
    };
}

const CardComponent = ({ card }: CardComponentProps) => {
    const [isFlipped, setIsFlipped] = useState(false);
    const [imageLoading, setImageLoading] = useState(true);

    const handleImageLoad = () => {
        setImageLoading(false);
    };

    return (
        <div className={`card-component ${isFlipped ? "flipped" : ""}`} onClick={() => setIsFlipped(!isFlipped)}>
            <div className="card-inner">
                <div className="card-front">
                    <div className="image-container">
                        <img
                            src={card.imageSrc}
                            alt={card.title}
                            onLoad={handleImageLoad}
                            style={{ display: imageLoading ? 'none' : 'block' }}
                        />
                    </div>
                    <div className="text-container">
                        <h3 className="card-title">{card.title}</h3>
                    </div>
                </div>
                <div className="card-back">
                    <p className="card-description">{card.description}</p>
                </div>
            </div>
        </div>
    );
};

export default CardComponent;
