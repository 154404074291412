import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import './HeaderCollapse.less';
import LanguageSwitcher from '../../language-switcher/LanguageSwitcher';

const FullPageModal = ({ isOpen, onClose, isSubPage }: any) => {
    const intl = useIntl();

    const handleLinkClick = () => {
        onClose();
    };

    return (
        <div className={`header-collapse-modal ${isOpen ? 'active' : ''}`}>
            <nav className="header-collapse-modal-nav">
                <ul>
                    <li><a href="/" onClick={handleLinkClick}>{intl.formatMessage({ id: 'HOME' })}</a></li>
                    <li><a href="/about-us" onClick={handleLinkClick}>{intl.formatMessage({ id: 'ABOUT' })}</a></li>
                    <li><a href="/shop">{intl.formatMessage({ id: 'SHOP' })}</a></li>
                    <li><a href="/faq">{intl.formatMessage({ id: 'FAQ' })}</a></li>
                    <li><a href="/contact-us" onClick={handleLinkClick}>{intl.formatMessage({ id: 'CONTACT' })}</a></li>
                    <br />
                    <li><div onClick={handleLinkClick}><LanguageSwitcher isMobile={true}/></div></li>
                </ul>
            </nav>
        </div>
    );
};

const CircleButton = ({ onClick, isOpen }: any) => (
    <button className={`menu-circle-button ${isOpen ? 'close' : ''}`} onClick={onClick}>
        <span className={`bar ${isOpen ? 'close' : ''}`}></span>
        <span className={`bar ${isOpen ? 'close' : ''}`}></span>
        <span className={`bar ${isOpen ? 'close' : ''}`}></span>
    </button>
);

const HeaderCollapse = ({ isSubPage }: any) => {
    const [modalOpen, setModalOpen] = useState(false);
    const toggleModal = () => setModalOpen(!modalOpen);

    return (
        <div className="header-collapse">
            <CircleButton onClick={toggleModal} isOpen={modalOpen} />
            <FullPageModal isOpen={modalOpen} onClose={toggleModal} isSubPage={isSubPage} />
        </div>
    );
};

export default HeaderCollapse;
