import React, { ReactNode } from 'react';
import { IntlShape } from 'react-intl';

type FAQItem = {
  question: string;
  answer: ReactNode;
};

export const getFaqContent = (intl: IntlShape): FAQItem[] => [
  {
    question: intl.formatMessage({ id: 'FAQ_QUESTION_1' }),
    answer: intl.formatMessage({ id: 'FAQ_ANSWER_1' }),
  },
  {
    question: intl.formatMessage({ id: 'FAQ_QUESTION_2' }),
    answer: intl.formatMessage({ id: 'FAQ_ANSWER_2' }),
  },
  {
    question: intl.formatMessage({ id: 'FAQ_QUESTION_3' }),
    answer: intl.formatMessage({ id: 'FAQ_ANSWER_3' }),
  },
  {
    question: intl.formatMessage({ id: 'FAQ_QUESTION_4' }),
    answer: intl.formatMessage({ id: 'FAQ_ANSWER_4' }),
  },
  {
    question: intl.formatMessage({ id: 'FAQ_QUESTION_5' }),
    answer: intl.formatMessage({ id: 'FAQ_ANSWER_5' }),
  },
  {
    question: intl.formatMessage({ id: 'FAQ_QUESTION_6' }),
    answer: intl.formatMessage({ id: 'FAQ_ANSWER_6' }),
  },
];
