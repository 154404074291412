import SkinIcon from "../../src/assets/icons/skin.svg";
import Lung from "../../src/assets/icons/lung.svg";
import Digestion from "../../src/assets/icons/stomach.svg";
import Muscle from "../../src/assets/icons/muscle.svg";
import Nutrient from "../../src/assets/icons/nutrient.svg";
import NervousSystem from "../../src/assets/icons/nervous-system.svg";

import { IntlShape } from "react-intl";

export const getBenefitsContent = (intl: IntlShape) => [
    { 
        imageSrc: SkinIcon,        
        title: intl.formatMessage({ id: "NOURISHES_THE_SKIN" }),    
        description: intl.formatMessage({ id: "NOURISHES_THE_SKIN_DESC" }) 
    },
    { 
        imageSrc: Lung,          
        title: intl.formatMessage({ id: "IMPROVE_RESPIRATORY_HEALTH" }),   
        description: intl.formatMessage({ id: "IMPROVE_RESPIRATORY_HEALTH_DESC" }) 
    },
    { 
        imageSrc: Digestion,         
        title: intl.formatMessage({ id: "AIDS_DIGESTION" }),    
        description: intl.formatMessage({ id: "AIDS_DIGESTION_DESC" }) 
    },
    { 
        imageSrc: NervousSystem,          
        title: intl.formatMessage({ id: "BOOST_IMMUNITY" }),   
        description: intl.formatMessage({ id: "BOOST_IMMUNITY_DESC" }) 
    },
    { 
        imageSrc: Nutrient,         
        title: intl.formatMessage({ id: "NUTRITIONAL_SUPLEMENT" }),    
        description: intl.formatMessage({ id: "NUTRITIONAL_SUPLEMENT_DESC" }) 
    },
    { 
        imageSrc: Muscle,    
        title: intl.formatMessage({ id: "ENHANCES_VITALITY" }),      
        description: intl.formatMessage({ id: "ENHANCES_VITALITY_DESC" }) 
    },
];
