import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import halalLogo from "../../assets/images/halal-logo.png";
import "./HalalSection.less";

const HalalSection = () => {
    const intl = useIntl();

    return (
        <div className="halal-container">
            <div className="halal-left-container">
                <img src={halalLogo} alt="halal-logo" className="halal-logo" />
            </div>
            <div className="halal-right-container">
                <h2 className="halal-title">{intl.formatMessage({ id: "HALAL_TITLE" })}</h2>
                <p className="halal-text">{intl.formatMessage({ id: "HALAL_TEXT1" })}</p>
                <p className="halal-text">{intl.formatMessage({ id: "HALAL_TEXT2" })}</p>
            </div>
      </div>
    );
}

export default HalalSection;