import { SET_LANGUAGE, useLayout } from "../../hooks/LayoutContext";
import './LanguageSwitcher.less';

interface Props {
    refHero?: any;
    isBlack?: any;
    isSubPage?: any;
    isMobile?: any;
}
const LanguageSwitcher = ({ refHero, isBlack, isSubPage, isMobile }: Props) => {
    const { layoutDispatch } = useLayout();

    const getLanguage = (loc: string) => {
        localStorage.setItem('pfxl', loc);
        layoutDispatch({ type: SET_LANGUAGE, payload: loc });
        if (!isSubPage && !isMobile) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }
    }

    return (
        <div className={`language-switcher-container ${isBlack ? 'blacked' : ''}`}>
            <p>
                <span onClick={() => getLanguage('en')} className={`text ${isBlack ? 'blacked' : ''}`}>EN</span>
                <span onClick={() => getLanguage('zh-CN')} className={`text ${isBlack ? 'blacked' : ''}`}>中文</span>
                {/* <span onClick={() => getLanguage('ms')}>BM</span> */}
            </p>
        </div>
    )
}

export default LanguageSwitcher;