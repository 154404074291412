// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDBhTO3OHDSsqjhI6ntr6KUH37MrbGKctk",
  authDomain: "forestbirdnest.firebaseapp.com",
  projectId: "forestbirdnest",
  storageBucket: "forestbirdnest.appspot.com",
  messagingSenderId: "904074461069",
  appId: "1:904074461069:web:6aed4b03c12ad1ec84c7c4",
  measurementId: "G-27VG3N612E"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);