import React, { useState } from "react";
import ReactLazyPreload from '../utils/ReactLazyPreload';

import logo from '../assets/images/logo-black.svg';

import './Content.less';
import Header from "../components/header/header-top/Header";
import Hero from "../components/hero/Hero";
import HalalSection from "../components/halal-section/HalalSection";
import BenefitsComponent from "../components/benefits/Benefits";
import Footer from "../components/footer/Footer";
import WhatsappButton from "../components/whatsapp-button/WhatsappButton";
import SubHero from "../components/sub-hero/SubHero";
import LandingContent from "../components/landing-content/LandingContent";
import HeaderCollapse from "../components/header/header-collapse/HeaderCollapse";
import CallToAction from "../components/call-to-action-column/CallToAction";
import HeroMobile from "../components/hero/HeroMobile";

const Content = () => {
    return (
        <div className="content-container">
            <Header isSubPage={false}/>
            <HeaderCollapse isSubPage={false}/>
            <Hero />
            <HeroMobile />
            <SubHero />
            <LandingContent />
            <CallToAction />
            <HalalSection />
            <BenefitsComponent />
            <WhatsappButton />
            <Footer />
        </div>
    );
};

export default Content;
