import React, { useEffect } from 'react';
import card1 from '../../assets/images/card-1.png';
import card2 from '../../assets/images/card-2.png';
import card3 from '../../assets/images/card-3.png';
import card4 from '../../assets/images/card-4.png';
import './LandingContent.less';
import { useIntl } from 'react-intl';

const LandingContent = () => {
    const intl = useIntl();

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;

            // Select the background elements
            const backgroundDiv1 :any = document.querySelector('.background-div-1');
            const backgroundDiv3 :any = document.querySelector('.background-div-3');

            if (backgroundDiv1) {
                backgroundDiv1.style.transform = `translateY(${scrollPosition * 0.2}px)`; // Parallax effect with slower movement
            }
            if (backgroundDiv3) {
                backgroundDiv3.style.transform = `translateY(${scrollPosition * 0.15}px)`; // Parallax effect with slower movement
            }
        };

        // Add scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="landing-content-container">
            <div className="background-div-1"></div>
            <div className="landing-content-1">
                <div className="landing-content-image-container-1">
                    <img src={card1} alt="Birdnest House" className="landing-content-image-1" />
                </div>
                <div className="landing-content-text-1">
                    <h1 className="landing-content-title-1">{intl.formatMessage({ id: "LANDING_CONTENT_TITLE_1" })}</h1>
                    <h2 className="landing-content-subtitle-1">{intl.formatMessage({ id: "LANDING_CONTENT_SUBTITLE_1" })}</h2>
                    <div className="title-line title-line-left"></div>
                    <p className="landing-content-description-1">{intl.formatMessage({ id: "LANDING_CONTENT_DESC_1" })}</p>
                </div>
            </div>
            <div className="landing-content-2">
                <div className="landing-content-image-container-2">
                    <img src={card2} alt="Birdnest House" className="landing-content-image-2" />
                </div>
                <div className="landing-content-text-2">
                    <h1 className="landing-content-title-2"><span>{intl.formatMessage({ id: "100%" })}</span> {intl.formatMessage({ id: "LANDING_CONTENT_TITLE_2" })}</h1>
                    <div className="title-line title-line-right"></div>
                    <p className="landing-content-description-2">{intl.formatMessage({ id: "LANDING_CONTENT_DESC_2" })}</p>
                </div>
            </div>
            <div className="landing-content-3">
                <div className="background-div-3"></div>
                <div className="landing-content-image-container-3">
                    <img src={card3} alt="Birdnest House" className="landing-content-image-3" />
                </div>
                <div className="landing-content-text-3">
                    <h2 className="landing-content-subtitle-3-1"><span>{intl.formatMessage({ id: "ZERO" })}</span>{intl.formatMessage({ id: "LANDING_CONTENT_SUBTITLE_3_1" })}</h2>
                    <h2 className="landing-content-subtitle-3-2"><span>{intl.formatMessage({ id: "ZERO" })}</span>{intl.formatMessage({ id: "LANDING_CONTENT_SUBTITLE_3_2" })}</h2>
                    <h2 className="landing-content-subtitle-3-3"><span>{intl.formatMessage({ id: "ZERO" })}</span>{intl.formatMessage({ id: "LANDING_CONTENT_SUBTITLE_3_3" })}</h2>
                    <div className="title-line title-line-left"></div>
                    <p className="landing-content-description-3">{intl.formatMessage({ id: "LANDING_CONTENT_DESC_3" })}</p>
                </div>
            </div>
            <div className="landing-content-4">
                <div className="landing-content-image-container-4">
                    <img src={card4} alt="Birdnest House" className="landing-content-image-4" />
                </div>
                <div className="landing-content-text-4">
                    <h1 className="landing-content-title-4">{intl.formatMessage({ id: "LANDING_CONTENT_TITLE_4" })}</h1>
                    <div className="title-line title-line-right"></div>
                    <p className="landing-content-description-4">{intl.formatMessage({ id: "LANDING_CONTENT_DESC_4" })}</p>
                </div>
            </div>
        </div>
    );
};

export default LandingContent;
