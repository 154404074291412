import React from "react";
import { useIntl } from "react-intl";
import { companyName, companyRegistrationNumber, contactNumber, enquiryEmail, factoryAddress, fbContactLink, xhsContactLink, igContactLink } from "../../content/CompanyContent";
import logo from "../../assets/images/logo/logo-gold.svg";
import mapIcon from "../../assets/icons/icons8-location-50.png";
import emailIcon from "../../assets/icons/icons8-email-50.png";
import phoneIcon from "../../assets/icons/icons8-call-50.png";
import { ReactComponent as FacebookIcon } from "../../assets/icons/icons8-facebook.svg";
import FacebookIcon2 from "../../assets/icons/icons8-facebook.svg";
import XHSIcon from "../../assets/icons/xiaohongshu.svg";
import { ReactComponent as InstagramIcon } from "../../assets/icons/icons8-instagram.svg";

import './Footer.less';

export default function Footer({ isSubPage }: any) {
    const intl = useIntl();
    return (
        <div className="footer-container">
            <div className="footer-content">
                <div className="footer-row">
                    <div className="footer-logo-container">
                        <img src={logo} className="footer-logo-img" alt="Logo" />
                        <p>{companyName}<br />{companyRegistrationNumber}</p>
                    </div>
                    <div className="footer-links-container">
                        <a href="/" className="link">{intl.formatMessage({ id: "HOME" })}</a>
                        <a href="/about-us" className="link">{intl.formatMessage({ id: "ABOUT" })}</a>
                        <a href="/shop" className="link">{intl.formatMessage({ id: "SHOP" })}</a>
                        <a href="/faq" className="link">{intl.formatMessage({ id: "FAQ" })}</a>
                        <a href="/contact-us" className="link">{intl.formatMessage({ id: "CONTACT" })}</a>
                    </div>
                    <div className="footer-contact-container">
                        <div className="footer-contact-address">
                            <img src={mapIcon} alt="Map Icon" className="icon" />
                            <div className="footer-contact-address-text">
                            {factoryAddress.split("\n").map((line: any, idx: any) => (
                                <div key={idx}>
                                    {line}
                                </div>
                            ))}
                            </div>
                        </div>
                        <p className="footer-contact-email">
                            <img src={emailIcon} alt="Email Icon" className="icon" />
                            <a href={`mailto:${enquiryEmail}`}>{enquiryEmail}</a>
                        </p>
                        <p className="footer-contact-phone">
                            <img src={phoneIcon} alt="Phone Icon" className="icon" />
                            {contactNumber}
                        </p>
                    </div>
                    <div className="social-links-container">
                        <a href={fbContactLink} className="social-link">
                            {/* <FacebookIcon /> */}
                            <img src={FacebookIcon2} alt="xhs Icon" className="icon" />
                        </a>
                        <a href={igContactLink} className="social-link">
                            <InstagramIcon />
                        </a>
                        <a href={xhsContactLink} className="social-link">
                            <img src={XHSIcon} alt="xhs Icon" className="icon" />
                        </a>
                    </div>
                </div>
                <p className="copyright-text">
                    {intl.formatMessage({ id: "COPYRIGHT" }, { currentYear: new Date().getFullYear() })}
                    <span><br />{companyRegistrationNumber}</span>
                </p>
            </div>
        </div>
    );
}
