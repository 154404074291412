import React from 'react';
import productImage from '../../assets/images/mobile-product.png'; // The image you want to use as the background
import logoImage from '../../assets/images/logo/logo-gold.png';
import './HeroMobile.less';
import { useIntl } from 'react-intl';

const HeroMobile = () => {
    const intl = useIntl();
    const isChineseLocale = intl.locale === 'zh-CN';

    return (
        <div className="hero-mobile-container">
            <div className="hero-mobile-header-background">
                <header className="hero-mobile-header">
                    <img src={logoImage} alt="Logo" className="logo" />
                    <div className="hero-mobile-header-line">
                        <h1 className="hero-mobile-company-name">Forest Birdnest</h1>
                        <h1 className="hero-mobile-company-name-cn">森之燕燕窝</h1>
                    </div>
                </header>
            </div>
            <div className="hero-mobile-content">
                <div className="background-image">
                    <div className={`hero-mobile-title ${isChineseLocale ? 'hero-mobile-title-zh' : ''}`}>
                        <h1>100<span>%</span></h1>
                        <p className={`hero-subtitle ${isChineseLocale ? 'hero-subtitle-zh' : ''}`}>
                            {intl.formatMessage({ id: "HERO_SLOGAN_1" })}
                            <br />
                            {intl.formatMessage({ id: "HERO_SLOGAN_2" })}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeroMobile;
