import React from 'react';

import './CallToAction.less';
import { useIntl } from 'react-intl';

const CallToAction = () => {
    const intl = useIntl();

    return (
        <div className="cta-container">
            <div className="cta-text">
                <p className="cta-subtitle">{intl.formatMessage({ id: 'CTA_SUBTITLE' })}</p>
                <h1 className="cta-title">{intl.formatMessage({ id: 'CTA_TITLE' })}</h1>
                <div className="cta-arrow">
                    <hr />
                    <span>&rarr;</span>
                </div>
            </div>
            <a href="/shop">
                <div className="action-button">
                    {intl.formatMessage({ id: 'SHOP' })}
                </div>
            </a>
        </div>
    );
};

export default CallToAction;
