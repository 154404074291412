import React, { useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { getBenefitsContent } from "../../content/BenefitsContent";
import CardComponent from '../card-component/CardComponent';
import './Benefits.less';
// import CardCarousel from "../card-carousel/CardCarousel";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const BenefitsComponent = ({ benefitFeatures }: any) => {
    const intl = useIntl();
    const cardRefs = useRef<(HTMLDivElement | null)[]>([]);
    const BenefitsContent = getBenefitsContent(intl);
    const responsive = {
        superLargeDesktop: {
          breakpoint: { max: 4000, min: 2200 },
          items: 6,
        },
        largeDesktop: {
          breakpoint: { max: 2199, min: 2000 },
          items: 5,
        },
        mediumDesktop: {
          breakpoint: { max: 1999, min: 1430 },
          items: 4.25,
        },
        smallDesktop: {
          breakpoint: { max: 1429, min: 1280 },
          items: 4,
        },
        extraSmallDesktop: {
          breakpoint: { max: 1279, min: 1100 },
          items: 3.5,
        },
        bigTablet: {
          breakpoint: { max: 1099, min: 913 },
          items: 3,
        },
        mediumTablet: {
          breakpoint: { max: 912, min: 821 },
          items: 2.5,
        },
        ipadAir: {
          breakpoint: { max: 820, min: 769 },
          items: 2.25,
        },
        tablet: {
          breakpoint: { max: 768, min: 560 },
          items: 2,
        },
        smallTablet: {
          breakpoint: { max: 559, min: 431 },
          items: 1,
        },
        mobile: {
          breakpoint: { max: 430, min: 0 },
          items: 1
        }
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('visible');
                        observer.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.1 }
        );

        cardRefs.current.forEach((ref) => {
            if (ref) {
                observer.observe(ref);
            }
        });

        return () => {
            observer.disconnect();
        };
    }, [BenefitsContent]);

    return (
        <div id="Benefits" ref={benefitFeatures} className="benefits-container">
            <h1 className="benefits-heading">
                <span className="benefits-sub-heading">{intl.formatMessage({ id: "BENEFITS_SUB_HEADING" })}</span>
                {intl.formatMessage({ id: "BENEFITS_HEADING" })}
            </h1>
            <div className="vertical-spacer" />

            <div className="background-rectangle"></div>

            <div className="benefits-card-container">
                {BenefitsContent.map((card, i) => (
                    <div className="benefits-column visible" key={i} ref={(el) => (cardRefs.current[i] = el)}>
                        <CardComponent card={card} />
                    </div>
                ))}
            </div>

            <div className="card-carousel-benefits">
                <Carousel responsive={responsive}>
                {BenefitsContent.map((card, index) => (
                    <CardComponent 
                    key={index} 
                    card={{ 
                        imageSrc: card.imageSrc, 
                        title: card.title, 
                        description: card.description 
                    }} 
                    />
                ))}
                </Carousel>
            </div>
        </div>
    );
};

export default BenefitsComponent;
