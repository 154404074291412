import React, { useState, useEffect, useRef } from 'react';
import Header from '../../components/header/header-top/Header';
import HeaderCollapse from '../../components/header/header-collapse/HeaderCollapse';
import WhatsappButton from '../../components/whatsapp-button/WhatsappButton';
import Footer from '../../components/footer/Footer';
import appLogo from '../../assets/images/logo/logo-gold-with-word.svg';
import img1 from '../../assets/images/about-us-1.png';
import img2 from '../../assets/images/about-us-2.png';
import { useIntl } from 'react-intl';
import './AboutUs.less';

const AboutUs = () => {
    const [isScrollButtonVisible, setIsScrollButtonVisible] = useState(false);
    const intl = useIntl();
    
    const aboutUsContent2Ref = useRef<HTMLDivElement>(null);
    const backgroundRectangleRef = useRef<HTMLDivElement>(null);
    const leafLeftRef = useRef<HTMLDivElement>(null);
    const leafRightRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (aboutUsContent2Ref.current && backgroundRectangleRef.current) {
            const contentHeight = aboutUsContent2Ref.current.clientHeight;
            backgroundRectangleRef.current.style.height = `${contentHeight}px`;

            const contentTopOffset = aboutUsContent2Ref.current.offsetTop;
            backgroundRectangleRef.current.style.top = `${contentTopOffset - 50}px`;
        }
    }, []);

    // Parallax effect for the leaves
    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY;

            if (leafLeftRef.current) {
                leafLeftRef.current.style.transform = `translateY(${scrollY * 0.3}px)`;
            }

            if (leafRightRef.current) {
                leafRightRef.current.style.transform = `translateY(${scrollY * 0.5}px)`;
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="about-us-container">
            <Header isSubPage={true} />
            <HeaderCollapse isSubPage={true} />

            <div ref={leafLeftRef} className="shop-leaf-left-about-us"></div>
            <div ref={leafRightRef} className="shop-leaf-right-about-us"></div>
            <div ref={backgroundRectangleRef} className="about-us-background-rectangle"></div>

            <div className="about-us-header">
                <div className="about-us-header-logo-container">
                    <img alt="company-logo" className="about-us-header-logo" src={appLogo} />
                </div>
                <div className="about-us-title-container">
                    <p className="about-us-title">{intl.formatMessage({ id: "ABOUT_US_VISION_TITLE" })}</p>
                </div>
            </div>
            <div className="about-us-vision">
                {/* <div className="about-us-vision-title-container">
                    <h2 className="about-us-vision-title">{intl.formatMessage({ id: "ABOUT_US_VISION_TITLE" })}</h2>
                </div> */}
                <div className="about-us-vision-text-container">
                    <p className="about-us-vision-text">{intl.formatMessage({ id: "ABOUT_US_VISION_TXT" })}</p>
                </div>
            </div>
            <div className="about-us-header-2">
                <div className="about-us-title-container">
                    <p className="about-us-title">{intl.formatMessage({ id: "ABOUT_US_HEADER" })}</p>
                </div>
            </div>
            <div className="about-us-content-1">
                <div className="about-us-content-1-img-container">
                    <img alt="company-logo" className="about-us-content-1-img" src={img1} />
                </div>
                <div className="about-us-content-1-text-container">
                    <h2 className="about-us-content-1-header">{intl.formatMessage({ id: "ABOUT_US_CONTENT_1_HEADER" })}</h2>
                    <p className="about-us-content-1-text">{intl.formatMessage({ id: "ABOUT_US_CONTENT_1_TXT" })}</p>
                </div>
            </div>
            <div className="about-us-content-2" ref={aboutUsContent2Ref}>
                <div className="about-us-content-2-img-container">
                    <img alt="company-logo" className="about-us-content-2-img" src={img2} />
                </div>
                <div className="about-us-content-2-text-container">
                    <p className="about-us-content-2-text">{intl.formatMessage({ id: "ABOUT_US_CONTENT_2_TXT" })}</p>
                </div>
            </div>

            <WhatsappButton isScrollButtonVisible={isScrollButtonVisible} />
            <Footer isSubPage={true} />
        </div>
    );
};

export default AboutUs;
