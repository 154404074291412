import React from 'react';
import { useIntl } from 'react-intl';
import logo from '../../../assets/images/logo-gold.svg';
// import sampleFile from '../../../assets/MultilineTrading.pdf'; // Adjust the path to your file
import './Header.less';
import LanguageSwitcher from '../../language-switcher/LanguageSwitcher';
import logoImage from '../../../assets/images/logo/logo-gold.png';


const Header = ({ isSubPage }: any) => {
    const intl = useIntl();

    return (
        <nav className="floating-nav">
            <div className="hero-mobile-header-background">
                <header className="hero-mobile-header">
                    <img src={logoImage} alt="Logo" className="logo" />
                    <div className="hero-mobile-header-line">
                        <h1 className="hero-mobile-company-name">Forest Birdnest</h1>
                        <h1 className="hero-mobile-company-name-cn">森之燕燕窝</h1>
                    </div>
                </header>
            </div>
            <div className="nav-container">
                <ul className="nav-links">
                    <li><a href="/">{intl.formatMessage({ id: 'HOME' })}</a></li>
                    <li><a href="/about-us">{intl.formatMessage({ id: 'ABOUT' })}</a></li>
                    <li><a href="/shop">{intl.formatMessage({ id: 'SHOP' })}</a></li>
                    <li><a href="/faq">{intl.formatMessage({ id: 'FAQ' })}</a></li>
                    <li><a href="/contact-us">{intl.formatMessage({ id: 'CONTACT' })}</a></li>
                </ul>
                <div className="cta-button">
                    <LanguageSwitcher />
                </div>
            </div>
        </nav>
    );
};

export default Header;
