import React, { useEffect, useRef, useState } from 'react';
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from '../../firebaseConfig';
import { Card, Col, Row, Modal, Carousel, Button } from 'antd';
import './Shop.less';
import { salesContactLink, currencyCode } from '../../content/CompanyContent';
import Header from '../../components/header/header-top/Header';
import Footer from '../../components/footer/Footer';
import logo from '../../assets/images/logo/logo-gold-with-word.svg';
import WhatsappButton from '../../components/whatsapp-button/WhatsappButton';
import HeaderCollapse from '../../components/header/header-collapse/HeaderCollapse';
import { useIntl } from 'react-intl';

const { Meta } = Card;

const Product = () => {
    const intl = useIntl();
    const [items, setItems] = useState<any[]>([]);
    const [filteredItems, setFilteredItems] = useState<any[]>([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState<any>(null);
    const [productImages, setProductImages] = useState<string[]>([]);

    const shopLeafLeftRef = useRef<HTMLDivElement>(null);
    const shopLeafRightRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        fetchItems();
    }, [intl.locale]);

    useEffect(() => {
        handleSearch(searchQuery);
    }, [searchQuery, items]);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            if (shopLeafLeftRef.current) {
                shopLeafLeftRef.current.style.transform = `translateY(${scrollPosition * 0.4}px)`;
            }
            if (shopLeafRightRef.current) {
                shopLeafRightRef.current.style.transform = `translateY(${scrollPosition * 0.4}px)`;
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        fetchItems();
    }, [intl.locale]);

    useEffect(() => {
        handleSearch(searchQuery);
    }, [searchQuery, items]);

    const fetchItems = async () => {
        try {
            const itemsQuery = query(collection(db, "items"), orderBy("sequence"));
            const itemsSnapshot = await getDocs(itemsQuery);
            let itemsData = itemsSnapshot.docs.map(doc => {
                const data = doc.data();
                const currentLocale = intl.locale;
    
                return {
                    id: doc.id,
                    name: data.name[currentLocale] || data.name['en'],
                    shortDesc: data.shortDesc[currentLocale] || data.shortDesc['en'],
                    longDesc: data.longDesc[currentLocale] || data.longDesc['en'],
                    price: data.price || "",
                    sequence: data.sequence || 0, // Ensure you have a sequence field
                };
            });
    
            const itemsWithMainPhotos = await Promise.all(itemsData.map(async item => {
                const photosQuery = query(collection(db, "item-photos"), where("itemId", "==", item.id));
                const photosSnapshot = await getDocs(photosQuery);
                const photos = photosSnapshot.docs.map(doc => ({
                    photoUrl: doc.data().photoUrl,
                    isMain: doc.data().isMain,
                }));
                const mainPhoto = photos.find(photo => photo.isMain)?.photoUrl || photos[0]?.photoUrl || '';
                return { ...item, mainPhoto, photos: photos.map(photo => photo.photoUrl) };
            }));
    
            setItems(itemsWithMainPhotos);
            setFilteredItems(itemsWithMainPhotos); 
        } catch (error) {
            console.error("Error fetching items: ", error);
        }
    };
    

    const handleSearch = (value: string) => {
        setSearchQuery(value);
        const lowercasedQuery = value.toLowerCase();
        const filteredData = items.filter(item =>
            item.name.toLowerCase().includes(lowercasedQuery) ||
            item.shortDesc.toLowerCase().includes(lowercasedQuery)
        );
        setFilteredItems(filteredData);
    };

    const handleCardClick = (item: any) => {
        setSelectedProduct(item);
        setProductImages(item.photos || []); 
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedProduct(null);
        setProductImages([]);
    };

    const handleBuyNow = (selectedProduct: any) => {
        const whatsappUrl = `${salesContactLink}&text=${encodeURIComponent(`I would like to buy ${selectedProduct}`)}`;
        window.open(whatsappUrl, '_blank');
        setIsModalOpen(false);
    };

    return (
        <div className="shop-page-wrapper">
            <div ref={shopLeafLeftRef} className="shop-leaf-left"></div>
            <div ref={shopLeafRightRef} className="shop-leaf-right"></div>
            <div className="shop-container">
                <Header isSubPage={true}/>
                <HeaderCollapse isSubPage={true}/>
                <div className="title-logo-container">
                    <img src={logo} alt="Logo" className="logo" />
                    <h1 className="shop-title">{intl.formatMessage({ id: "OUR_PRODUCTS_TITLE" })}</h1>
                </div>

                <div className="product-container">
                    <Row gutter={[16, 16]}>
                        {filteredItems.map(item => (
                            <Col key={item.id} xs={24} sm={12} md={8} lg={6}>
                                <Card
                                    style={{ height: '100%' }}
                                    hoverable
                                    cover={<img alt={item.name} src={item.mainPhoto} />}
                                    onClick={() => handleCardClick(item)}
                                >
                                    <Meta title={item.name} description={item.shortDesc} />
                                </Card>
                            </Col>
                        ))}
                    </Row>

                    {selectedProduct && (
                        <Modal
                            title={selectedProduct.name}
                            open={isModalOpen}
                            onCancel={handleCloseModal}
                            footer={null}
                        >
                            <div className="product-modal-content">
                                <Carousel arrows>
                                    {productImages.map((image, index) => (
                                        <div key={index}>
                                            <img src={image} alt={`Product image ${index + 1}`} style={{ width: '100%' }} />
                                        </div>
                                    ))}
                                </Carousel>
                                <p><strong>{selectedProduct.shortDesc}</strong></p>
                                <p>{selectedProduct.longDesc}</p>
                                <p><strong>{currencyCode} {selectedProduct.price}</strong></p>
                                <div className="product-modal-footer">
                                    <Button onClick={() => handleBuyNow(selectedProduct.name)} type="primary" className="buy-now-btn">{intl.formatMessage({ id: "BUY_NOW" })}</Button>
                                </div>
                            </div>
                        </Modal>
                    )}
                </div>
                <WhatsappButton />
                <Footer isSubPage={true} />
            </div>
        </div>
    );
};

export default Product;
